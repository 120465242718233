import { HttpClient } from "compass-shared-services";
import { getUrl } from "compass-commons";
import LiveStreamInfoResponseDTO from "compass-widget-library/src/models/LiveStreamInfoResponseDTO";
import { ResourceMapping } from "../model/resource/ResourceMapping";
import { ResourceCommandAdditionalInfo } from "../model/resource/ResourceCommand";
import { ResourcesStateResponse } from "../model/resource/ResourcesStateResponse";
import { getErrorLabelFromRMMService } from "../helpers/errorMapperHelper";
import DatabaseSearchRequestDTO from "../model/databaseSearch/DatabaseSearchRequestDTO";
import DatabaseSearchResponseDTO from "../model/databaseSearch/DatabaseSearchResponseDTO";

const { RESOURCE_MAPPING_MANAGER_PATH, SEARCH_TASK_PAGE_SIZE } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  RESOURCE_DETAIL: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping`,
  RESOURCES_STATES: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping/states`,
  SEND_COMMAND: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping/(resourceMappingId)/send-command`,
  DATABASE_SEARCH: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping/database-search`,
  LIVESTREAM_INFO: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping/(resourceMappingId)/get-livestream`,
};

const CURRENT_STATE = "CURRENT_STATE";

/**
 * Incident Service is used to call related BE apis for incidents.
 */
export default class ResourceMappingService {
  private static readonly RESOURCE_MAPPING_ID = "resourceMappingId";

  private static readonly ADDITIONAL_DATA = "additionalData";

  private static resourceMappingsCache = new Map<string, ResourceMapping>();

  private static isRMRequestLoading = false;

  private static readonly RETRY_TIMEOUT = 100;

  private static readonly CACHE_SIZE = 200;

  private static readonly PAGE_SIZE = parseInt(SEARCH_TASK_PAGE_SIZE);

  static async getResourceById(id: string): Promise<ResourceMapping> {
    const urlPath = `${URL_PATHS.RESOURCE_DETAIL}/${id}`;
    return httpClient
      .get<ResourceMapping>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const label = getErrorLabelFromRMMService(error?.data.errorCode);
        throw label;
      });
  }

  static async sendCommand(
    resourceMappingId: string,
    resourceCommandAdditionalInfo: ResourceCommandAdditionalInfo,
    functionalityId: string
  ): Promise<any> {
    const uri = {
      resourceMappingId,
    };
    const urlPath = getUrl(URL_PATHS.SEND_COMMAND, uri);
    return httpClient
      .post({
        url: urlPath,
        payload: {
          commandId: functionalityId, // required for api call
          sourceName: resourceCommandAdditionalInfo.sourceName, // required for api call
          siteName: resourceCommandAdditionalInfo.siteName, // required for api call
          behaviorName: resourceCommandAdditionalInfo.behaviorName, // required for api call
          sourceFloorPlanName:
            resourceCommandAdditionalInfo?.sourceFloorPlanName,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw (
          error?.data.errorMessage ||
          getErrorLabelFromRMMService(error?.data.errorCode)
        );
      });
  }

  /**
   * @deprecated The method should not be used
   */
  static async getResourcesStates(
    resourceMappingsIds: string[]
  ): Promise<ResourcesStateResponse> {
    const urlPath = URL_PATHS.RESOURCES_STATES;
    return httpClient
      .post<ResourcesStateResponse>({
        url: urlPath,
        payload: { resources: resourceMappingsIds },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getResourcesMappings(
    resourceMappingsIds: string[]
  ): Promise<ResourceMapping[]> {
    const urlPath = URL_PATHS.RESOURCE_DETAIL;
    const params = new URLSearchParams();
    resourceMappingsIds.forEach((id) =>
      params.append(this.RESOURCE_MAPPING_ID, id)
    );
    params.append(this.ADDITIONAL_DATA, CURRENT_STATE);
    const config = {
      params,
    };
    return httpClient
      .get<ResourceMapping[]>({
        url: urlPath,
        config,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  /**
   * Get resource mapping by id from cache if exists, otherwise fetch from BE
   * Only one request is allowed at a time
   */
  static async getResourceByIdCached(id: string): Promise<ResourceMapping> {
    if (this.resourceMappingsCache.get(id)) {
      return this.resourceMappingsCache.get(id);
    }
    if (!this.isRMRequestLoading) {
      this.isRMRequestLoading = true;

      return this.getResourceById(id)
        .then((response) => {
          this.resourceMappingsCache.set(id, response);
          // Remove first value if cache size is exceeded
          if (this.resourceMappingsCache.size > this.CACHE_SIZE) {
            this.resourceMappingsCache.delete(
              this.resourceMappingsCache.keys().next().value
            );
          }
          this.isRMRequestLoading = false;
          return response;
        })
        .catch((error) => {
          this.isRMRequestLoading = false;
          throw error;
        });
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          this.getResourceByIdCached(id).catch((error) => {
            throw error;
          })
        );
      }, this.RETRY_TIMEOUT);
    });
  }

  static async databaseSearch(
    resourceTags: string[],
    query: string,
    page: number,
    resourceTriggerRMId: string
  ): Promise<DatabaseSearchResponseDTO> {
    const body = {
      tags: resourceTags,
      filter: query,
      pageData: {
        pageNumber: page,
        pageSize: ResourceMappingService.PAGE_SIZE,
      },
      resourceTriggerResourceMappingId: resourceTriggerRMId,
    } as DatabaseSearchRequestDTO;
    return httpClient
      .post<DatabaseSearchResponseDTO>({
        url: URL_PATHS.DATABASE_SEARCH,
        payload: body,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getLiveStreamInfo(
    resourceMappingId: string
  ): Promise<LiveStreamInfoResponseDTO> {
    const uri = {
      resourceMappingId,
    };
    const urlPath = getUrl(URL_PATHS.LIVESTREAM_INFO, uri);
    return httpClient
      .get<LiveStreamInfoResponseDTO>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
