import React from "react";
import { useI18n } from "compass-commons";

import { Box, Container, Typography } from "@mui/material";
import DatabaseSearchResult from "../../../../../../../model/databaseSearch/internal/DatabaseSearchResult";
import { SingleInfoField } from "./SingleInfoField";
import { OptionType } from "../../../../../../../model/databaseSearch/internal/OptionType";

interface OptionInformationProps {
  option: DatabaseSearchResult;
}

function OptionInformation(props: OptionInformationProps): React.JSX.Element {
  const { option } = props;
  const { t: translate } = useI18n();

  return option.type === OptionType.DEFAULT ? (
    <Container>
      {option.infoFields.map((item, index) => (
        <SingleInfoField key={index} infoField={item} index={index} />
      ))}
    </Container>
  ) : (
    // Not found
    <Container className="og-search-task-dropdown-option-information-default">
      <Box>
        <Typography
          data-cr="not-found-text"
          className="pivot-typography-body-1"
        >
          {option.textToDisplay ||
            translate("incident.operatorGuide.dbSearchTask.resultNotFound")}
        </Typography>
      </Box>
    </Container>
  );
}

export default OptionInformation;
